import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(() => ({
  LMaskBackdrop: {
    zIndex: 9999,
    color: '#fff',
    position: "absolute",
  },
  LMaskTitle: {
    paddingRight: 10
    ,paddingLeft: 10
  }
}));

const LoadingMask = (props) => {

    const classes = useStyles();

    const getImage = () => {
      return(
        <div style={{textAlign: 'center'}}>
          <img src={props.image} width="400" ></img>
          <div>{props.loadingText}</div>
        </div>
      )
    }

    return (
        <Backdrop className={classes.LMaskBackdrop} open={props.open}>
            <CircularProgress color="inherit" />
            <Typography variant="caption" className={classes.LMaskTitle}>
                {props.image ? getImage() : props.loadingText}
            </Typography>
        </Backdrop>
    )
}

LoadingMask.defaultProps = {
    open: false
    ,loadingText: 'Cargando, espere un momento..'
    ,image: null
}

LoadingMask.propTypes = {
  loadingText: PropTypes.string.isRequired
}

export default LoadingMask;
